.habitTracker {
  text-align: center;
  background-color: #222b35;
  color: white;
}
.habitTracker .habit:hover {
  cursor: pointer;
}

.habitTracker .habit.strike {
  color: red;
  text-decoration: line-through;
}

.habitTracker .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.habitTracker .right {
  position: absolute;
  right: 0px;
  width: 300px;
  padding: 30px;
}

.habitTracker table {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  border: 1px solid #595959;
}

.habitTracker table td,
.habitTracker table th,
.habitTracker table tr {
  border: 1px solid #595959;
  justify-content: center;
}

.habitTracker table td {
  /* Prevent highligting of symbols */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.habitTracker table td.hoverable:hover {
  border: 1px solid white;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.habitTracker .togglePercent {
  cursor: pointer;
}

.habitTracker input {
  text-align: center;
  color: white;
  background-color: #333f4f;
}

.habitTracker .AddHabit {
  float: center;
}

.habitTracker .complete {
  background-color: #005c2a;
}
.habitTracker .missed {
  background-color: #571712;
}
.habitTracker .inactiveCells {
  background-color: #333f4f;
}
.habitTracker .padding {
  padding: 20px;
}

.habitTracker h1 {
  margin: 0px;
  padding-top: 2vh;
}

.habitTracker .gridContainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  text-align: center;
}

.habitTracker button {
  cursor: pointer;
  color: white;
  padding: 5px 15px;
  text-align: center;
  font-size: 16px;
}
.habitTracker .setPrevMonth,
.habitTracker .setNextMonth {
  background-color: #333f4f;
  border: 1px solid white;
  margin-top: 10px;
}
.habitTracker .deleteButton {
  background-color: #571712;
  border: 1px solid #595959;
  margin-bottom: 15px;
}

.habitTracker .wrapper {
  position: fixed;
  background-color: #222b35;
  min-height: 100%;
  min-width: 100%;
  justify-content: center;
}
.habitTracker .buttonLink {
  background: none !important;
  padding: 0 !important;
  border: none;
  color: #4193f0;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
}

.habitTracker .signInBox {
  outline: 1px solid white;
  padding: 10px;
  margin-top: 30px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
